.cf-link		{ text-decoration: underline; }
.cf-link:hover	{ text-decoration: underline; font-weight: bolder; cursor: pointer; }
.cf-float-left	{ float: left;				}
.cf-float-right { float: right;				}
.cf-clear-right	{ clear: right;				}
.cf-clear-left	{ clear: left;				}
.cf-clear		{ clear: both;				}
.cf-justify		{ text-align: justify		}
.cf-right		{ text-align: right;		}
.cf-center		{ text-align: center;		}
.cf-top			{ vertical-align: top;		}
.cf-middle		{ vertical-align: middle;	}
.cf-bottom		{ vertical-align: bottom;	}
.cf-avatar		{ border-radius: 50%;		}
.cf-resetcursor	{ cursor: default			}
.cf-field		{
	text-align: left;
	background-color: #fff;
	padding:.67857143em 1em;
	background-color:#f0f0f0;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	overflow: hidden;
}

.cf-image-field	{
	cursor: pointer;
	text-align: center;
	position: relative;
	background-color: #f0f0f0;
	padding:.67857143em 1em;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	margin-right: .5em;
	overflow: hidden;
}

.cf-image-field > img {
	height: 96px;
}

.cf-image-field > nav {
	position: absolute;
	top: 4px;
	right: 4px;
}

.cf-empty-card > div {
	cursor: pointer;
	background-color: #f0f0f0;
	text-align: center;
	vertical-align: middle;
	padding: 48px;
	height: 100%;
}

.cf-image-card > div {
	cursor: pointer;
	position: relative;
	background-color: #f0f0f0;
	padding: 8px;
	height: 100%;
}

.cf-image-card > nav {
	position: absolute;
	bottom: 8px;
	right: 4px;
}

.cf-action-bar-alert {
	margin-top: -6px;
}

.cf-floater {
	pointer-events:none;
	position:fixed;
	z-index: 9999;

}

.cf-indicator {
	top:0;
	right:0;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	font-size:20px;
	color:white;
	background-color:#6488EA;
	border: 2px solid #324475;
	border-radius:8px;
	margin:8px;
	padding:8px;
	opacity:75%;
}