@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6GcOrgs.ttf) format('truetype');
}
@font-face {
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/archivoblack/v17/HTxqL289NzCGg4MzN6KJ7eW6CYyF-w.ttf) format('truetype');
}
@font-face {
  font-family: 'Big Shoulders Text';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bigshoulderstext/v15/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q7TUFMg.ttf) format('truetype');
}
@font-face {
  font-family: 'Candal';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8c9BhQN.ttf) format('truetype');
}
@font-face {
  font-family: 'Crete Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxK170bg.ttf) format('truetype');
}
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dancingscript/v23/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup5.ttf) format('truetype');
}
@font-face {
  font-family: 'Grenze Gotisch';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/grenzegotisch/v11/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9kd4qZ.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Rozha One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2kRcn38.ttf) format('truetype');
}
