.add-course-card {
	background-color:#dbdbdb!important;
	border-color:grey!important;
	border-style:dashed!important;
	border-width:medium!important;
	min-height:320px!important;
}

.funnel-preview-frame {
	border-style: solid;
	border-width: 0px;
	border-color: #c0c0c0;
	padding: 0;
	margin: 0;
	width: 100%;
	min-height:80vh;
}

.moveable-image .moveable-line {
    position: absolute;
    width: -7px !important;
    height: 7px !important;
    background: #4af !important;
    transform-origin: 0px 0.5px;
}

.moveable-image .moveable-control {
    position: absolute;
    width: 28px!important;
    height: 28px!important;
    border-radius: 50%;
    border: 4px solid #fff !important;
    box-sizing: border-box;
    background: #4af;
    margin-top: -14px !important;
    margin-left: -14px !important;
    z-index: 10;
}

.moveable-image .moveable-origin {
	display: none !important;
}

.moveable-label .moveable-line {
    position: absolute;
    width: -7px !important;
    height: 7px !important;
    background: #4af !important;
    transform-origin: 0px 0.5px;
}

.moveable-label .moveable-control {
    position: absolute;
    width: 28px!important;
    height: 28px!important;
    border-radius: 50%;
    border: 4px solid #fff !important;
    box-sizing: border-box;
    background: #4af;
    margin-top: -14px !important;
    margin-left: -14px !important;
    z-index: 10;
}

.moveable-label .moveable-origin {
	display: none !important;
}